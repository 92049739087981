export const epronRoleOptions = [
  { label: "Read", value: "read" },
  { label: "Write", value: "write" },
];

export const roleOptions = [
  {label: "Collector", value: "collector"},
  {label: "Epron", value: "epron"},
  {label: "Manufacturer", value: "manufacturer"},
  {label: "Recycler", value: "recycler"},
]
